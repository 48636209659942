import React from "react";

const Pagination = ({ currentIndex, verse, maxIndex, onNext, onPrevious }) => {
  // Use the verse date as the display index
  const displayIndex = verse?.date || 0;

  return (
    <div className="container mx-auto flex justify-center mb-4 mt-12">
      <div className="join">
        <button
          className="join-item btn"
          onClick={onNext}
          disabled={currentIndex >= maxIndex}
        >
          «
        </button>
        <button className="join-item btn">{displayIndex}</button>
        <button
          className="join-item btn"
          onClick={onPrevious}
          disabled={currentIndex === 0}
        >
          »
        </button>
      </div>
    </div>
  );
};

export default Pagination;
