// Import necessary hooks and components
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Books } from "../Assets/books";
import "../Main/Main.css";
import { GrTwitter as ShareBtn } from "react-icons/gr";
import { IoHome } from "react-icons/io5";
// Import the local JSON file
import YLTData from '../Assets/YLT.json';

const fetchVerseByPK = async (pk) => {
  const dataArray = Object.values(YLTData); // Convert the data object to an array
  const selectedVerse = dataArray.find((v) => v.pk === pk);
  if (selectedVerse) {
    return selectedVerse;
  } else {
    throw new Error("No verse found with the generated pk");
  }
};

export const Index = () => {
  const { pk } = useParams();
  const navigate = useNavigate();
  const paragraphRef = useRef(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const {
    data: verse,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["verse", pk],
    queryFn: () => fetchVerseByPK(parseInt(pk)),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // Add the onError handler if needed
    onError: (err, query) => {
      console.error("Query error:", err);
    },
  });
  
  useEffect(() => {
    if (paragraphRef.current) {
      const paragraphLength = paragraphRef.current.innerText.length;
      if (paragraphLength > 149) {
        paragraphRef.current.id = "scaledown";
      } else if (paragraphLength > 400) {
        paragraphRef.current.id = "scaledown2";
      } else {
        paragraphRef.current.id = "";
      }
    }
  }, [verse]);

  const handleShareClick = () => {
    if (verse) {
      const urlToCopy = `${window.location.origin}/read/${verse.pk}`;
      navigator.clipboard.writeText(urlToCopy).then(() => {
        alert("Link copied to clipboard!");
      }).catch((err) => {
        console.error("Failed to copy link: ", err);
      });
    }
  };

  const handleRefreshClick = () => {
    setIsLoadingButton(true);
    refetch().finally(() => {
      setIsLoadingButton(false);
    });
    navigate("/read");
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text text-color">
          <p ref={paragraphRef}>
            {isLoading ? (
              <span className="loading loading-ring loading-md"></span>
            ) : (
              verse?.text
            )}
          </p>
          <footer>
            {isLoading ? (
              <span className="loading loading-ring loading-sm"></span>
            ) : (
              `- ${Books[verse.book]}. ${verse.chapter}:${verse.verse}`
            )}
            {isLoading ? (
              <span></span>
            ) : (
              <div className="icons">
                <ShareBtn onClick={handleShareClick} />
              </div>
            )}
          </footer>
        </blockquote>
      </div>
      <footer className="mt-12">
        <button
          className="btn"
          onClick={handleRefreshClick}
        >
          {isLoadingButton ? (
            <>
              <span className="loading loading-spinner"></span>
              loading
            </>
          ) : (
            "Refresh Verse"
          )}
        </button>
        <button className="btn" onClick={() => navigate("/")}>
          <IoHome />
        </button>
      </footer>
    </>
  );
};
