import React, { useEffect, useRef, useState } from "react";
import { ref, get, child } from "firebase/database";
import { database } from "../../API/firebase";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Books } from "../Assets/books";
import "../Main/Main.css";
import { Link } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import { GrTwitter as ShareBtn } from "react-icons/gr";

const fetchRandomVerse = async () => {
  const dbRef = ref(database);
  const snapshot = await get(child(dbRef, `YLT`));
  if (snapshot.exists()) {
    const data = snapshot.val();
    const dataArray = Object.values(data); // Convert the data object to an array
    const minPk = 23215;
    const maxPk = 54306;
    const randomPk = Math.floor(Math.random() * (maxPk - minPk + 1)) + minPk;
    const selectedVerse = dataArray.find((v) => v.pk === randomPk);
    if (selectedVerse) {
      return selectedVerse;
    } else {
      throw new Error("No verse found with the generated pk");
    }
  } else {
    throw new Error("No data available");
  }
};

const Fire = () => {
  const queryClient = useQueryClient();
  const paragraphRef = useRef(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem("hasPrefetched")) {
      queryClient
        .prefetchQuery({
          queryKey: ["randomVerse"],
          queryFn: fetchRandomVerse,
        })
        .then(() => {
          sessionStorage.setItem("hasPrefetched", "true");
        });
    }
  }, [queryClient]);

  const {
    data: verse,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["randomVerse"],
    queryFn: fetchRandomVerse,
    refetchOnWindowFocus: false, // Disable refetch on window focus
    refetchOnMount: false, // Disable refetch on mount
  });

  useEffect(() => {
    if (!hasFetched && !verse) {
      refetch().finally(() => setHasFetched(true));
    }
  }, [hasFetched, refetch, verse]);

  useEffect(() => {
    if (paragraphRef.current) {
      const paragraphLength = paragraphRef.current.innerText.length;
      if (paragraphLength > 149) {
        paragraphRef.current.id = "scaledown";
      } else {
        paragraphRef.current.id = "";
      }
    }
  }, [verse]);

  const handleRefreshClick = () => {
    setIsLoadingButton(true);
    refetch().finally(() => {
      setIsLoadingButton(false);
    });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text text-color">
          <p ref={paragraphRef}>
            {isLoading ? (
              <span className="loading loading-ring loading-md"></span>
            ) : (
              verse?.text
            )}
          </p>
          <footer>
            {isLoading ? (
              <span className="loading loading-ring loading-sm"></span>
            ) : (
              `- ${Books[verse.book]}. ${verse.chapter}:${verse.verse}`
            )}
            {isLoading ? (
              <span></span>
            ) : (
              <div className="icons">
                <ShareBtn />
              </div>
            )}
          </footer>
        </blockquote>
      </div>
      <footer className="mt-12">
        <button
          className="btn"
          onClick={handleRefreshClick}
        >
          {isLoadingButton ? (
            <>
              <span className="loading loading-spinner"></span>
              loading
            </>
          ) : (
            "Refresh Verse"
          )}
        </button>
        <Link to="/">
          <button className="btn">
            <IoHome />
          </button>
        </Link>
      </footer>
    </>
  );
};

export default Fire;
