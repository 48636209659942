import React, { useState, useEffect, useRef } from "react";
import "./Main.css";
import { Books } from "../Assets/books";
import { ref, get, child, runTransaction } from "firebase/database";
import { database } from "../../API/firebase";
import { GrTwitter as ShareBtn } from "react-icons/gr";
import { FaHeart as LikeBtn } from "react-icons/fa6";
import { useQuery } from "@tanstack/react-query";
import Pagination from "../Utils/Pagination";
import { Menu } from "../Utils/Menu";

const handleLikePress = (pk, isLiked) => {
  const likesRef = ref(database, `verses/${pk}/likes`);
  runTransaction(likesRef, (currentLikes) => {
    return isLiked ? (currentLikes || 0) - 1 : (currentLikes || 0) + 1;
  });
};

const fetchVerse = async (index) => {
  const dbRef = ref(database);
  const snapshot = await get(child(dbRef, `verses/${index}`));
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    throw new Error("Verse not available");
  }
};

const fetchVersesCount = async () => {
  const dbRef = ref(database);
  const snapshot = await get(child(dbRef, `verses`));
  if (snapshot.exists()) {
    return snapshot.val().length; // Assuming verses are in an array
  } else {
    throw new Error("No data available");
  }
};

export const Main = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [likedVerses, setLikedVerses] = useState({});
  const [likes, setLikes] = useState(null);
  const paragraphRef = useRef(null);

  const { data: verse, error } = useQuery({
    queryKey: ["verse", currentIndex],
    queryFn: () => fetchVerse(currentIndex),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: versesCount } = useQuery({
    queryKey: ["versesCount"],
    queryFn: fetchVersesCount,
  });

  useEffect(() => {
    if (verse) {
      setLikes(verse.likes); // Set initial likes
    }
  }, [verse]);

  useEffect(() => {
    if (paragraphRef.current && verse) {
      const paragraphLength = paragraphRef.current.innerText.length;
      paragraphRef.current.id =
        paragraphLength > 149
          ? "scaledown"
          : paragraphLength > 400
          ? "scaledown2"
          : "";
    }
  }, [verse]);

  useEffect(() => {
    if (verse) {
      const isLiked = sessionStorage.getItem(`liked_${verse.pk}`) === "true";
      setLikedVerses((prevState) => ({
        ...prevState,
        [verse.pk]: isLiked,
      }));
    }
  }, [verse]);

  const handleClick = (pk) => {
    const isLiked = sessionStorage.getItem(`liked_${pk}`) === "true";
    const newLikes = isLiked ? likes - 1 : likes + 1;
    setLikedVerses((prevState) => ({
      ...prevState,
      [pk]: !isLiked,
    }));
    setLikes(newLikes); // Update the likes state immediately
    handleLikePress(pk, isLiked);
    sessionStorage.setItem(`liked_${pk}`, !isLiked);
  };

  const openNextPost = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  const openPreviousPost = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text">
          <p ref={paragraphRef}>{verse?.text}</p>
          <footer>
            - {Books[verse?.book]}. {verse?.chapter}:{verse?.verse}
            <div className="icons">
              <ShareBtn />
              <LikeBtn
                style={{
                  color: likedVerses[verse?.pk] ? "#FF00FF" : "white",
                  fontSize: likedVerses[verse?.pk] ? "20px" : "17px",
                }}
                onClick={() => handleClick(verse?.pk)}
              />
            </div>
          </footer>
        </blockquote>
      </div>
      <Pagination
        currentIndex={currentIndex}
        verse={verse} // Pass the entire verse object
        maxIndex={versesCount - 1} // Pass the total number of
        onNext={openNextPost}
        onPrevious={openPreviousPost}
      />
      <Menu
        verse={verse}
        likes={likes}
        isClicked={likedVerses[verse?.pk]}
        handleClick={() => handleClick(verse?.pk)}
      />
    </>
  );
};
