// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, runTransaction } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBs8alPqBLyzpJYy4fVdcKyk-wbpdU3jJk",
  authDomain: "chance-app-3ff76.firebaseapp.com",
  databaseURL: "https://chance-app-3ff76-default-rtdb.firebaseio.com",
  projectId: "chance-app-3ff76",
  storageBucket: "chance-app-3ff76.appspot.com",
  messagingSenderId: "543252167038",
  appId: "1:543252167038:web:6c6828e9a6fd5aedf09b2f",
  measurementId: "G-NELM4PGM3T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export { database, analytics, ref, runTransaction };