import React from "react";
import "../Footer/Footer.css"

export const Footer = () => {
  return (
    <p className="note">
      <a
        href="https://drafts.csswg.org/css-shapes-2/#propdef-shape-inside"
        target="_blank"
        rel="noreferrer"
      >
        Note: A future level of CSS Shapes will define a shape-inside property,
        which will define a shape to wrap content within the element.
      </a>
    </p>
  );
};
