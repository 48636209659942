import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Books } from '../Assets/books';
import { supabase } from '../../API/supabaseClient';

const fetchSupabaseData = async (page, itemsPerPage) => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage - 1;
    let { data, error } = await supabase
      .from('2024us')
      .select('*')
      .order('date', { ascending: false }) // Replace 'date' with your relevant column
      .range(start, end);
    if (error) throw error;
    return data;
  };
  

const Home = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 1; // Adjust this as needed

  const { data, error, isFetching } = useQuery({
    queryKey: ['supabaseData', page],
    queryFn: () => fetchSupabaseData(page, itemsPerPage),
  });

  const [text, setText] = useState('');
  const [footer, setFooter] = useState('');

  useEffect(() => {
    if (data && data.length > 0) {
      setText(data[0].text);
      setFooter(`- ${Books[data[0].book]}. ${data[0].chapter}:${data[0].verse}`);
    }
  }, [data]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const prevPage = () => setPage((prev) => prev + 1);
  const nextPage = () => setPage((prev) => Math.max(prev - 1, 1));

  return (
    <>
      <div className="quote-wrapper">
        <blockquote className="text text-color">
          <p>{text}</p>
          <footer>
            {footer}
            <div className="icons">
              {/* <ShareBtn onClick={handleShareClick} /> */}
            </div>
          </footer>
        </blockquote>
      </div>
      <footer className="mt-12">
        <button className="btn" onClick={prevPage} disabled={isFetching}>
          Previous
        </button>
        <button className="btn" onClick={nextPage} disabled={isFetching}>
          Next
        </button>
      </footer>
    </>
  );
};

export default Home;
