import React from "react";
//import { FaHeart } from "react-icons/fa6";
import { GiPerspectiveDiceSixFacesRandom as RandomBtn } from "react-icons/gi";
import { Link } from "react-router-dom";
//import { FaBookBible } from "react-icons/fa6";
//import { Chapter } from "../Chapter/Chapter";

export const Menu = ({ random, verse, isClicked, handleClick, likes }) => {
  // const handleClick = () => {
  // //  event.preventDefault();
  // //  console.log("Button clicked");
  //   random().catch((error) => console.error("Error in random function:", error));
  // };

  const openModal = () => {
    document.getElementById("my_modal_4").showModal();
  };

  return (
    <div className="container flex mx-auto justify-center mt-12">
      <ul className="menu menu-horizontal bg-base-200 rounded-box items-center">
        <li>
          <a
            href="/"
            className="btn"
            style={{ fontSize: "24px" }}
            onClick={openModal}
          >
            {/* <Chapter verse={verse} /> */}
          </a>
        </li>
        <li>
          
          <Link
            to="/read"
            className="btn"
            style={{ fontSize: "24px" }}
          >
              <RandomBtn />
          </Link>
        </li>

        <li>
          <button
            className="btn"
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <div className="badge badge-secondary">+{likes}</div>
          </button>
        </li>
      </ul>
    </div>
  );
};
