import "./App.css";
import Fire from "./Components/Fire/Fire";
import { Footer } from "./Components/Footer/Footer";
import { Header } from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import { Index } from "./Components/Index/Index";
import { Main } from "./Components/Main/Main";
import { Read } from "./Components/Read/Read";
import { BrowserRouter, Routes, Route } from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Main/>} />
        <Route path="/browse/:pk" element={<Main />}/>
        <Route path="/read" element={<Read/>} />
        <Route path="/read/:pk" element={<Index/>} />
        <Route path="/fire" element={<Fire/>} />
        <Route path="/home" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
